<template>
  <v-container id="new-event-forms-view" tag="section">
    <validation-observer ref="observer" v-slot="{ invalid, handleSubmit }">
      <v-form @submit.prevent="handleSubmit(submit)">
        <v-card>
          <v-card-text>
            <v-row>
              <v-col cols="6" sm="6">
                <VueCtkDateTimePicker v-bind="$attrs" :disabled="!valid" v-model="arrivalTime" no-clear-button color="#f64e65"
                  label="Na miejscu" format="YYYY-MM-DD HH:mm" formatted="dddd, DD MMMM YYYY HH:mm">
                </VueCtkDateTimePicker>
              </v-col>
              <v-col cols="6" sm="6">
                <v-text-field :disabled="!valid" dense outlined v-model="travelTime"
                  :label="$t('task.stats.travel.time') + ' (sekundy)'" hide-details="auto"></v-text-field>
              </v-col>
            </v-row>
            <v-row>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <app-btn class="mx-1" :disabled="!valid" default type="submit">{{
              $t("btn.save")
            }}</app-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-form>
    </validation-observer>
  </v-container>
</template>

<script>
// import _ from "lodash"
import { required } from "vee-validate/dist/rules"
import { extend, ValidationObserver, /*ValidationProvider*/ } from "vee-validate"
import TaskService from "@/services/task.service"
import { parseJSON, addSeconds } from 'date-fns'

export default {
  components: {
    // ValidationProvider,
    ValidationObserver
  },
  name: "NewSite",

  beforeMount() {
    const task = this.$route.params.task
    if (task) {
      this.setTask(task)
    }
    if (this.$route.params.taskId) {
      if (!task) {
        TaskService.get({ siteId: this.$route.params.siteId, taskId: this.$route.params.taskId }).then(task => {
          this.setTask(task)
        })
      }
    }
  },
  mounted() {
    extend("required", {
      ...required
    })
  },
  beforeDestroy() { },
  computed: {
    beginTimeIso() {
      return this.beginTime?.toISOString()
    }
  },
  data: () => ({
    // Search data
    task: {
      details: {
        stats: {}
      }
    },
    valid: true,
    readAt: null,
    beginTime: null,
    departureAt: null,
    arrivalTime: null,
    travelTime: 0,
    siteId: 0
  }),
  watch: {
    // travelTime(value) {
    //   console.log('travelTie :>> ', value);
    //   if (!this.valid) {
    //     return
    //   }
    //   this.arrivalTime = this.$options.filters?.localDateTime(
    //     addSeconds(this.beginTime, value)
    //   )
    // },
    arrivalTime(value) {
      console.log('arivalTime :>> ', value);
      if (!this.valid) {
        return
      }
      this.travelTime = Math.round((new Date(value) - this.beginTime) / 1000)
    }
  },
  methods: {
    setTask(task) {
      this.task = task
      this.beginTime = parseJSON(task.details.stats.travel_started_at)
      this.valid = this.beginTime instanceof Date && !isNaN(this.beginTime)
      if (!this.valid) {
        return
      }
      this.travelTime = task.details.stats.travel_time
      this.arrivalTime = this.$options.filters?.localDateTime(
        addSeconds(this.beginTime, this.travelTime)
      )
    },
    submit() {
      TaskService.updateStats({
        siteId: this.$route.params.siteId,
        taskId: this.$route.params.taskId,
        stats: {
          travel_time: this.travelTime
        }
      })
        .then(() => {
          this.$router.back()
        })
    }
  }
}
</script>
